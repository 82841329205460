import { createStore } from 'vuex'
// import {ref} from 'vue'
import createPersistedState from "vuex-persistedstate"
// import { dataStore } from './module';

// speichert nur shopkaffees im lokalstorage
const dataState=createPersistedState({
  // paths: ['ShopKaffees','KaffeeDerWoche']
  paths: ['ShopKaffees']
})


export default createStore({
  state: {
    KaffeeDerWoche:{id:'A001', Name:"Äthiopischer Djimmah", Land: "Äthiopien", img: "/images/aethiopien.jpg"},
    Kaffees:[],
    ShopKaffees:[]
  },
  getters: {
    findKaffee: (state) => (id:number) => {
      return state.Kaffees?.filter((x:any)=>x.id==id)[0];
     },
    // getKaffees: (state) => {
    //   fetch("Kaffeesorten.json").then(response=>response.json().then(data=>state.Kaffees=data))
    //   return true;
    // }
    
  },
  mutations: {
    addKaffee2Shop (state:any, obj:any){
      const k = state.ShopKaffees.findIndex((x: any) => x["id"] == obj["id"] && x.gebinde == obj.gebinde);
      obj.mahlgrad="Ganze Bohne"
      if(k>=0){
        state.ShopKaffees[k]["menge"]++;
      } else {
        state.ShopKaffees.push(obj);
      }
    },
    removeKaffeeFromShop(state:any, obj:any){
      const k = state.ShopKaffees.findIndex((x: any) => x["id"] == obj["id"] && x.gebinde == obj.gebinde);
      if(k>=0){
        if (state.ShopKaffees[k]["menge"] >= 1) state.ShopKaffees[k]["menge"] = state.ShopKaffees[k]["menge"]-1;
        if (state.ShopKaffees[k]["menge"]==0) {
          delete state.ShopKaffees[k];
          state.ShopKaffees=state.ShopKaffees.filter((x:any)=>x != null);
        }
      }
    },
    setKaffees(state: any, data:Array<object>) { 
      state.Kaffees=data;
    }
  },
  actions: {
    async getKaffees(){
      return fetch("Kaffeesorten.json").then(response => response.json()).then(data => (this.commit('setKaffees', data)));
    }
  },
  modules: {
  },
  plugins: [dataState],

  
})


