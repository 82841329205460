import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {BootstrapVue3} from 'bootstrap-vue-3'

//import "bootstrap/dist/css/bootstrap.min.css"
import "@/styles/_cofstyle.scss";
import "bootstrap/scss/bootstrap.scss"

import CofOeffnungszeiten from '@/components/Oeffnungszeiten.vue';

store.dispatch('getKaffees');

createApp(App)
    .use(store)
    .use(BootstrapVue3)
    .use(router)
    .component('Oeffnungszeiten', CofOeffnungszeiten)
    .mount('#app')
    
